import React, { memo, useMemo, useState } from 'react';
import NavToggles from './NavToggles';
import { months, years } from '../../helper/monthsAndYears';
import { faMagnifyingGlass, faSignOut, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import useStoreHelper from "../../hooks/useStoreHelper";
import useNavigation from '../../hooks/useNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBoxList, PopUpPicker, SubmitButton, TagInput } from '../../components/inputs';
import { DatePicker, DoubleInput, DoubleInputWithOptions, MainSearchInput, SingleInput } from './inputs';

const DesktopNav = ({
    navType,
    enableAllSearch,
    enabledSearches,
    disableNavChoice,
    countBy, 
    fixedTop,

    defaultInputFilters,
    users,
    options,
    currentGroup,
    enableAdvanceSearch,
    highlightedDates,

    inputFilters, setInputFilters,
    group, setGroup,
    user, setUser,
    year, setYear,
    month, setMonth,
    setMinWeightUnit,
    setMaxWeightUnit,
    checked_categories,
    checked_sizes,

    changeCategories, 
    changeSizes,
    search,
    searchAll
}) => {

    const groups = useSelector( state => state.app.groups);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const { logOut } = useStoreHelper();
    const navigation = useNavigation();

    const [showScrollMask, setShowScrollMask] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const weightUnits = [
        {
            id: 1,
            name: 'ကျပ်',
            value: 'kyat'
        },
        {
            id: 2,
            name: 'ပဲ',
            value: 'pel'
        },
        {
            id: 3,
            name: 'ရွေး',
            value: 'yway'
        }
    ]

    const navButtons = useMemo( () => {
        switch (navType) {
            case 'groups':
                return enableAllSearch ? groups : groups.filter( e => e.id !== null);

            case 'users':
                return enableAllSearch ? users : users.filter( e => e.id !== null );
        
            case 'calendar':
                return [
                    {
                        id: null,
                        name: 'All'
                    },
                    ...months
                ];

            default:
                return enableAllSearch ? groups : groups.filter( e => e.id !== null );
        }
    }, [navType, groups, users, months]);

    const toggleEdgeMask = (e) => {
        const reachedLeft = e.target.scrollLeft <= 0;
        const reachedRight = e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth;

        if (reachedLeft || reachedRight) {
            setShowScrollMask(false);
        } else {
            setShowScrollMask(true);
        }
    }

    return (
        <div className={`z-20 top-0 left-0 right-0 ${ fixedTop ? 'fixed' : '' }`}>

            {/* nav bar */}
            <div className="flex flex-row bg-soft-black h-14">

                {/* route title */}
                <div className="flex items-center justify-center w-1/5">
                    <button 
                        className="flex items-center mr-3"
                        onClick={ () => navigation.navigate('sale') }
                    >
                        <span className={`${ (currentRoute.name === 'sale' ) ? 'font-selfie text-3xl text-white' : 'text-sm text-milk' }`}>
                            { (currentRoute.name === 'sale' ) ? 'Kaung San' : currentRoute.title }
                        </span>
                    </button>
                </div>

                { navType === 'calendar' ? (
                    <div className="flex items-center justify-center mr-3">
                        <PopUpPicker
                            triggerClassName="flex h-10 bg-soft-black justify-center items-center px-3 rounded border border-green"
                            disabled={disableNavChoice}
                            theme="dark"
                            data={years.map( year => {
                                return {
                                    id: year,
                                    name: year
                                }
                            })}
                            value={year}
                            onPick={setYear}
                            position="bottom left"
                        />
                    </div>
                ) : ''}

                <div
                    className={`flex-1 overflow-x-scroll ${showScrollMask ? 'mask-gradient' : ''}`}
                    onScroll={toggleEdgeMask}
                >
                    { navType === 'groups' ? (
                        <div className="flex flex-row items-center space-x-2 h-full">
                            { navButtons.map( e => (
                                <div
                                    key={e.id}
                                    className="flex items-center justify-center min-w-20 h-full"
                                >
                                    <button 
                                        className={`flex relative items-center justify-center h-10 rounded space-x-1
                                            bg-${ group === e.id ? e.color : '' }
                                        `}
                                        disabled={disableNavChoice}
                                        onClick={() => setGroup(e.id)}
                                    >
                                        <span className="text-sm text-white mx-3">
                                            { e.name }
                                        </span>

                                        { 
                                            countBy && 
                                            (countBy.length !== 0) && 
                                            (countBy.length === groups.length - 1) &&
                                            (e.id !== null && countBy.find( c => c.id === e.id).count) ? (
                                                <div 
                                                    className={`absolute flex items-center justify-center bg-red top-0 right-0 rounded-full 
                                                        ${ group === e.id ? 'border border-white' : ''}
                                                    `}
                                                >
                                                    <span className="text-white text-xs font-bold mx-1 min-w-3.5">
                                                        { countBy.find( c => c.id === e.id).count }
                                                    </span>
                                                </div>
                                            ) : ''
                                        }
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : ''}

                    { navType === 'users' ? (
                        <div className="flex flex-row items-center space-x-2 h-full">
                            { navButtons.map( e => (
                                <div
                                    key={e.id}
                                    className="flex items-center justify-center min-w-20 h-full"
                                >
                                    <button 
                                        className={`flex relative items-center justify-center h-10 rounded space-x-1
                                            ${ user === e.id ? 'bg-gray' : '' }
                                        `}
                                        disabled={disableNavChoice}
                                        onClick={() => setUser(e.id)}
                                    >
                                        <span className="text-sm text-white mx-3">
                                            { e.nickname }
                                        </span>

                                        { 
                                            countBy && 
                                            (countBy.length !== 0) && 
                                            (countBy.length === users.length - 1) &&
                                            (e.id !== null && countBy.find( c => c.id === e.id).count !== 0) ? (
                                                <div 
                                                    className="absolute flex items-center justify-center bg-red min-w-3.5 top-0 right-0 rounded-full"
                                                >
                                                    <span className="text-white text-xs font-bold mx-1 min-w-3.5">
                                                        { countBy.find( c => c.id === e.id).count }
                                                    </span>
                                                </div>
                                            ) : ''
                                        }
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : ''}

                    { navType === 'calendar' ? (
                        <div className="flex flex-row items-center space-x-2 h-full">
                            { navButtons.map( e => (
                                <div
                                    key={e.id}
                                    className="flex items-center justify-center min-w-20 h-full"
                                >
                                    <button 
                                        className={`flex items-center justify-center h-10 rounded space-x-1
                                            ${ month === e.id ? 'bg-gray' : '' }
                                        `}
                                        disabled={disableNavChoice}
                                        onClick={() => setMonth(e.id)}
                                    >
                                        <span className="text-sm text-white mx-3">
                                            { e.name }
                                        </span>
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : ''}

                    { navType === 'voucher_number_input' ? (
                        <div className="flex items-center h-full">
                            <div className="flex flex-row items-center h-10 w-full rounded overflow-hidden ml-5 mr-20">
                                
                                <MainSearchInput
                                    type="text"
                                    placeholder="ဘောင်ချာနံပါတ်ဖြင့်ရှာရန်..."
                                    value={inputFilters.id}
                                    onPressEnterKey={ () => search('id') }
                                    disabled={!enabledSearches.includes('id')}
                                    onChange={ text => setInputFilters({...defaultInputFilters, id: text.toUpperCase()})}
                                    onSearch={ () => search('id')}
                                    onRefresh={searchAll}
                                />
                            </div>
                        </div>
                    ) : ''}

                </div>

                <div className="flex items-center justify-between mx-3 lg:w-1/5">
                    <div className="mr-3">
                        <NavToggles/>
                    </div>
                    <SubmitButton
                        title="Log Out"
                        icon={faSignOut}
                        fullSize={false}
                        color="gray"
                        height="8"
                        fontSize="xs"
                        activated={isLoggingOut}
                        onClick={ () => logOut(setIsLoggingOut) }
                    />
                </div>
                
            </div>

            {/* search bar */}
            { enableAdvanceSearch ? (
                <div className="fixed h-5/6 left-0 bottom-0 w-64 border-8 shadow border-soft-black overflow-hidden bg-soft-black m-2 rounded-xl">
                    <div className="flex flex-col h-full">

                        <div className="flex flex-row items-center justify-between pt-2 pb-3 shadow-lg mx-1">
                            <span className="text-white text-2xl font-bold">
                                Search
                            </span>
                            <button 
                                className="flex flex-row items-center space-x-1 justify-center rounded bg-gray h-8 px-2"
                                onClick={searchAll}    
                            >
                                <FontAwesomeIcon icon={faUndo} color="white" size="sm"/>
                                <span className="text-white text-xs">Clear Filters</span>
                            </button>
                        </div>

                        {/* all search boxes */}
                        <div className="flex flex-col overflow-auto rounded">
                            <div className="flex flex-col space-y-4 my-5">

                                {/* id */}
                                {enabledSearches.includes('id') && navType !== 'voucher_number_input' ? (
                                    <SingleInput
                                        title="ပစ္စည်း ID"
                                        type="text"
                                        placeholder="Enter Item ID"
                                        value={inputFilters.id}
                                        onPressEnterKey={ () => search('id') }
                                        onChange={ text => setInputFilters({...defaultInputFilters, id: text })}
                                        onClick={() => search('id')}
                                    />
                                ) : ''}

                                {/* date */}
                                {enabledSearches.includes('date') ? (
                                    <DatePicker
                                        title="နေ့စွဲ"
                                        value={inputFilters.date}
                                        highlightedDates={highlightedDates}
                                        onChange={ newDate => setInputFilters({...inputFilters, date: newDate})}
                                        onClick={search}
                                    />
                                ) : ''}

                                {/* word */}
                                {enabledSearches.includes('word') ? (
                                    <SingleInput
                                        title="ပစ္စည်းအမည်"
                                        type="text"
                                        placeholder="eg. မောင်းကွင်း"
                                        value={inputFilters.word}
                                        onPressEnterKey={search}
                                        onChange={ text => setInputFilters({...inputFilters, word: text })}
                                        onClick={search}
                                    />
                                ) : ''}

                                {/* customer */}
                                {enabledSearches.includes('customer') ? (
                                    <>
                                        {/* customer name */}
                                        <SingleInput
                                            title="ဝယ်သူအမည်"
                                            type="text"
                                            placeholder="eg. ဒေါ်သီတာ"
                                            value={inputFilters.customer_name}
                                            onPressEnterKey={search}
                                            onChange={ text => setInputFilters({...inputFilters, customer_name: text })}
                                            onClick={search}
                                        />
                                        {/* customer address */}
                                        <SingleInput
                                            title="ဝယ်သူလိပ်စာ"
                                            type="text"
                                            placeholder="eg. ရွှေတောင်"
                                            value={inputFilters.customer_address}
                                            onPressEnterKey={search}
                                            onChange={ text => setInputFilters({...inputFilters, customer_address: text })}
                                            onClick={search}
                                        />
                                        {/* customer phone */}
                                        <SingleInput
                                            title="ဝယ်သူဖုန်းနံပါတ်"
                                            type="number"
                                            placeholder="eg. 09123456789"
                                            value={inputFilters.customer_phone}
                                            onPressEnterKey={search}
                                            onChange={ text => setInputFilters({...inputFilters, customer_phone: text })}
                                            onClick={search}
                                        />
                                    </>
                                ) : ''}

                                {/* price */}
                                {enabledSearches.includes('price') ? (
                                    <DoubleInput
                                        title="စျေးနှုန်း Range"
                                        type="number"
                                        left={{
                                            value: inputFilters.min_price,
                                            placeholder: 'အနည်းဆုံး',
                                            onChange: text => setInputFilters({...inputFilters, min_price: text})
                                        }}
                                        right={{
                                            value: inputFilters.max_price,
                                            placeholder: 'အများဆုံး',
                                            onChange: text => setInputFilters({...inputFilters, max_price: text})
                                        }}
                                        onPressEnterKey={search}
                                        onClick={search}
                                    />
                                ) : ''}

                                {/* type */}
                                { options.categories.length !== 0  && enabledSearches.includes('type') ? (
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-white text-xs">အမျိုးအစား</span>
                                        <CheckBoxList
                                            containerClassName="flex-1 bg-white rounded"
                                            rowClassName="h-14"
                                            data={options.categories}
                                            values={checked_categories}
                                            onSelect={changeCategories}
                                        />
                                    </div>
                                ) : ''}

                                {/* size */}
                                { options.sizes.length !== 0  && enabledSearches.includes('size') ? (
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-white text-xs">{ currentGroup.size_name }</span>
                                        <CheckBoxList 
                                            containerClassName="flex-1 bg-white rounded"
                                            rowClassName="h-14"
                                            data={options.sizes}
                                            values={checked_sizes}
                                            onSelect={changeSizes}
                                        />
                                    </div>
                                ) : ''}

                                {/* weight */}
                                {enabledSearches.includes('weight') ? (
                                    <DoubleInputWithOptions
                                        title="အလေးချိန် Range"
                                        type="number"
                                        left={{
                                            value: inputFilters.min_weight,
                                            placeholder: 'အနည်းဆုံး',
                                            onChange: text => setInputFilters({...inputFilters, min_weight: text}),
                                            option: {
                                                defaultValue: 2,
                                                data: weightUnits,
                                                onChange: weightUnitId => setMinWeightUnit(weightUnits.find( e => e.id === weightUnitId ).value)
                                            },
                                        }}
                                        right={{
                                            value: inputFilters.max_weight,
                                            placeholder: 'အများဆုံး',
                                            onChange: text => setInputFilters({...inputFilters, max_weight: text}),
                                            option: {
                                                defaultValue: 2,
                                                data: weightUnits,
                                                onChange: weightUnitId => setMaxWeightUnit(weightUnits.find( e => e.id === weightUnitId).value)
                                            },
                                        }}
                                        onPressEnterKey={search}
                                        onClick={search}
                                    />
                                ) : ''}

                                {/* user */}
                                { (enabledSearches.includes('user') && navType !== 'users' ) ? (
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-white text-xs">ဝန်ထမ်း</span>
                                        <div className="flex flex-row h-11 bg-white rounded">
                                            <PopUpPicker
                                                position="top center"
                                                triggerClassName="flex-1 flex justify-center items-center px-4 bg-white rounded-l"
                                                disabled={false}
                                                data={users}
                                                textColor="black"
                                                nullable={false}
                                                value={user}
                                                onPick={setUser}
                                            />
                                            <button
                                                className="px-3 bg-sky items-center justify-center rounded-r"
                                                onClick={search}
                                            >
                                                <div className="flex flex-row items-center justify-center space-x-1">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
                                                    <span className="text-white text-sm">Find</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                ) : ''}

                            </div>
                                
                        </div>

                    </div>
                </div>
            ) : ''}

        </div>
    );
};

export default memo(DesktopNav);

import React, { memo, useEffect, useMemo, useState } from "react";
import axios_custom from "../../axios_config/axios_custom";
import { useSelector } from 'react-redux';
import useStoreHelper from "../../hooks/useStoreHelper";
import { DatePicker, Input, LabeledSwitch, PopUpPicker, TagInput } from "../inputs";
import Modal from "./Modal";
import Collapsible from "../Collapsible";
import { faCheck, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaleVoucherPreviewModal from "./SaleVoucherPreviewModal";

const SaleVoucherFormModal = ({ 
    show, 
    onSold, 
    onClose, 
    data
}) => {

    const authUser = useSelector( state => state.app.authUser);
    const { handleError } = useStoreHelper();

    const { item, saleType } = data;
    const [showVoucherPreview, setShowVoucherPreview] = useState(false);
    const [voucherData, setVoucherData] = useState(null);

    //from api
    const [users, setUsers] = useState([]);

    const defaultInputValues = {
        date: new Date(),
        name: '',
        address: '',
        phone: '',
        discountPrice: '',
        tradeInPrice: '',
        depositPrice: '',
        hasDebt: false,
        hasTax: false,
        sellerId: authUser.id
    }
    const [inputs, setInputs] = useState(defaultInputValues);

    const [errorCheckable, setErrorCheckable] = useState(false);

    //mounted
        useEffect(() => {
            const controller = new AbortController();
            const signal = controller.signal;
        
            axios_custom.get('users', { signal })
            .then( res => {
                setUsers(res.data.results);
            })
            .catch((error) => {
                handleError(error);
            });
        
            return () => {
                controller.abort();
            };
        }, []);

    //error handlers    
        const inputErrors = useMemo( () => {
            const errors = [];

            inputs.name === '' && errors.push('name');
            
            if(inputs.hasDebt && inputs.depositPrice !== ''){
                if( Number(inputs.depositPrice) >= Number(item.price) ){
                    errors.push('deposit_price');
                }
            }
            return errors;
        }, [inputs.name, inputs.hasDebt, inputs.depositPrice]);

    //methods
        const onSoldClose = () => {
            setErrorCheckable(false);
            setInputs(defaultInputValues);
            onSold();
        }

        const submitToPreview = () => {
            setErrorCheckable(true);
            
            //submitting
            if ( inputErrors.length === 0 ) {
                setShowVoucherPreview(true);
                setVoucherData({
                    item,
                    saleType,
                    goldPrice: Number(item.quality.goldprice),
                    customer: {
                        name: inputs.name,
                        address: inputs.address,
                        phone: inputs.phone,
                    },
                    date: inputs.date,
                    hasDebt: inputs.hasDebt,
                    hasTax: inputs.hasTax,
                    tradeInPrice: Number(inputs.tradeInPrice),
                    depositPrice: Number(inputs.depositPrice),
                    discountPrice: Number(inputs.discountPrice),
                    soldBy: users.find( e => e.id === inputs.sellerId)
                });
            }
        }

    return (
        <>
            <Modal
                show={show}
                title={
                    <span className="font-selfie text-yellow text-2xl">
                        Kaung San
                    </span>
                }
                titlePosition="center"
                titleColor="yellow"
                submitButtonText="Preview Voucher"
                onClose={onClose}
                onSubmit={submitToPreview}
                isSubmitting={false}
            >
                <div className="flex flex-col space-y-5 mx-3 mt-5 mb-6">

                    <DatePicker
                        title="နေ့စွဲ"
                        value={inputs.date}
                        onChange={ newDate => setInputs({...inputs, date: newDate})}
                    />
                    
                    <Input
                        title="ဝယ်သူအမည်"
                        type="text"
                        placeholder="eg. ဦးမောင်မောင်"
                        value={inputs.name}
                        onChange={ text => setInputs({ ...inputs, name: text })}
                        errors={[
                            {
                                show: errorCheckable && inputErrors.includes('name'),
                                msg: 'ဝယ်သူအမည်ထည့်ပါ!'
                            }
                        ]}
                    />

                    <Input
                        title="ဝယ်သူလိပ်စာ"
                        type="text"
                        placeholder="eg. ရန်ကုန်"
                        value={inputs.address}
                        warningMsges={['မရှိလျှင်မထည့်ပဲထားခဲ့လဲရသည်။']}
                        onChange={ text => setInputs({ ...inputs, address: text })}
                    />

                    <Input
                        title="ဝယ်သူ Phone"
                        type="number"
                        placeholder="eg. 09123456789"
                        value={inputs.phone}
                        warningMsges={['မရှိလျှင်မထည့်ပဲထားခဲ့လဲရသည်။']}
                        onChange={ text => setInputs({ ...inputs, phone: text })}
                    />

                    <TagInput
                        title="လျှော့"
                        type="number"
                        tagLabel="ကျပ်"
                        value={inputs.discountPrice}
                        warningMsges={['မရှိလျှင်မထည့်ပဲထားခဲ့လဲရသည်။']}
                        onChange={ text => setInputs({ ...inputs, discountPrice: text })}
                    />

                    <TagInput
                        title="ပေးရွှေတန်ဖိုး"
                        type="number"
                        tagLabel="ကျပ်"
                        value={inputs.tradeInPrice}
                        warningMsges={['မရှိလျှင်မထည့်ပဲထားခဲ့လဲရသည်။']}
                        onChange={ text => setInputs({ ...inputs, tradeInPrice: text })}
                    />

                    <div className="flex flex-col space-y-2">
                        <span className="text-gray font-bold text-xs">
                            ဝယ်ယူသည့်ပုံစံ
                        </span>
                        <LabeledSwitch
                            value={inputs.hasDebt}
                            color="blue"
                            left={{
                                value: false,
                                label: 'လက်ငင်း'
                            }}
                            right={{
                                value: true,
                                label: 'ကြွေးကျန်'
                            }}
                            onChange={ newValue => setInputs({...inputs, hasDebt: newValue })}
                        />
                    </div>
                    
                    <Collapsible collapsed={!inputs.hasDebt}>
                        <div className="mt-5 mb-3">
                            <TagInput
                                title="အဝင် (စရံ)"
                                tagLabel="ကျပ်"
                                type="number"
                                value={inputs.depositPrice}
                                warningMsges={[
                                    'စရံ (သို့) အဝင်ငွေမပေးလျှင် မထည့်ပဲထားခဲ့ပါ။',
                                    '(သတိပြုရန်) ပေးရွှေတန်ဖိုးအား အဝင်အဖြစ် မထည့်ပါနှင့်။'
                                ]}
                                errors={[
                                    {
                                        show: errorCheckable && inputErrors.includes('deposit_price'),
                                        msg: 'အဝင်ပေးငွေသည် ကျသင့်ငွေနှင့် အတူတူဖြစ်နေပါသည် (သို့) ပိုများနေပါသည်!'
                                    }
                                ]}
                                onChange={ text => setInputs({ ...inputs, depositPrice: text })}
                            />
                        </div>
                    </Collapsible>

                    { authUser.abilities.includes('manage_users') ? (
                        <div className="flex flex-col space-y-2">
                            <span className="text-gray font-bold text-xs">
                                အရောင်းဝန်ထမ်း
                            </span>
                            <PopUpPicker
                                triggerClassName={`h-11 flex items-center pl-5 bg-white rounded border border-${ errorCheckable && inputErrors.includes('color') ? 'red' : 'dim-gray'}`}
                                disabled={false}
                                nullable={false}
                                value={inputs.sellerId}
                                theme="dark"
                                textColor="black"
                                enableIcon={true}
                                data={users}
                                position="top center"
                                onPick={ userId => setInputs({ ...inputs, sellerId: userId })}
                            />
                        </div>
                    ) : ''}

                    <div className="flex flex-row items-center space-x-2 ml-1">
                        <button 
                            className={`flex flex-row items-center justify-center space-x-2 rounded px-2 h-9 shadow ${ inputs.hasTax ? 'bg-soft-green' : 'bg-muted' }`}
                            onClick={ () => setInputs({...inputs, hasTax: !inputs.hasTax})}
                        >
                            <div className="flex items-center justify-center bg-white rounded h-4 w-4">
                                { inputs.hasTax ? (
                                    <FontAwesomeIcon icon={faCheck} color="black" size="2xs"/>
                                ) : ''}
                            </div>
                            <span className={`text-2xs ${ inputs.hasTax ? 'text-black' : 'text-soft-gray'}`}>
                                အခွန်ပါဝင်မည်
                            </span>
                        </button>
                        <FontAwesomeIcon icon={faReceipt} color={ inputs.hasTax ? 'black' : 'gray' }/>
                    </div>

                </div>
            </Modal>
            <SaleVoucherPreviewModal
                tasks={['sell', 'print']}
                show={showVoucherPreview}
                data={voucherData}
                onDone={onSoldClose}
                onClose={() => setShowVoucherPreview(false)}
            />
        </>
    );
};

export default memo(SaleVoucherFormModal);

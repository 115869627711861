import DateParser from "../../helper/dateParser";
import paperSizes from "../../helper/paperSizes";
import { memo, forwardRef } from "react";

const PayInterestVoucher = ({
    serial,
    item,
    amount,
    fromDate,
    toDate,
    receivedBy,
    paperSize = 'a4'
}, ref) => {

    const dateParser = new DateParser();
    const { width, height } = paperSizes.find( e => e.name === paperSize ).mm;
    const costPerMonth = Math.round((item.amount * item.rate) / 100);

    return (
        <div 
            ref={ref} 
            className="flex flex-col bg-white" 
            style={{width: `${width}mm`, height: `${height}mm`}}
        >
            <div 
                className="flex flex-col bg-white" 
                style={{width: `${width}mm`, height: `${height}mm`}}
            >
                <div className="flex flex-col">
                    <div className="flex flex-1 flex-row">
                        <div className="w-1/3"></div>
                        <div className="w-1/3">
                            <div className="flex flex-col space-y-5 px-3 pt-3 pb-5 border-b border-dashed">

                                <div className="flex flex-col space-y-2">
                                    <div className="flex flex-row items-center space-x-2 justify-center">
                                        <span className="font-myawaddy text-4xl">aumif;pH</span>
                                        <span className="font-serif font-bold text-2xs pt-2">စိန် ~ ရွှေရတနာဆိုင်</span>
                                    </div>
                                    <div className="flex justify-center">
                                        <span className="text-2xs text-gray">
                                            အတိုးရှင်းဘောင်ချာ
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="text-xs font-bold">
                                            ဘောင်ချာနံပါတ်
                                        </span>
                                        <span className="text-sm font-bold">
                                            { serial }
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="text-xs">
                                            အပေါင်ဘောင်ချာနံပါတ်
                                        </span>
                                        <span className="text-xs">
                                            { item.serial }
                                        </span>
                                    </div>
                                    <span className="text-xs">
                                        { item.customer_name } ({ item.customer_address })
                                    </span>
                                </div>

                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        တစ်လအတိုးနှုန်း
                                    </span>
                                    <span className="text-xs">
                                        { Number(item.rate) } %  ({ new Intl.NumberFormat().format(costPerMonth) } ကျပ်)
                                    </span>
                                </div>

                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-row justify-between items-center">
                                        <div className="flex flex-row items-center space-x-1">
                                            <span className="text-xs font-bold">
                                                { dateParser.toString(fromDate)}
                                            </span>
                                            <span className="text-2xs">
                                                မှ
                                            </span>
                                            <span className="text-xs font-bold">
                                                { dateParser.toString(toDate)}
                                            </span>
                                            <span className="text-2xs">
                                                ထိ
                                            </span>
                                        </div>
                                        <span className="text-xs">
                                            { dateParser.compareMonthDiff(fromDate, toDate) } လစာ
                                        </span>
                                        
                                    </div>
                                    <div className="flex flex-row justify-end">
                                        <span className="font-bold font-mono">
                                            သင့်ငွေ - { new Intl.NumberFormat().format(amount) }
                                        </span>
                                    </div>
                                </div>

                                <span className="text-2xs">
                                    တာဝန်ခံဝန်ထမ်း - { receivedBy.name }
                                </span>

                            </div>
                        </div>
                        <div className="w-1/3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(forwardRef(PayInterestVoucher));
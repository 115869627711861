import DateParser from "../../helper/dateParser";
import paperSizes from "../../helper/paperSizes";
import WeightStats from "../WeightStats";
import { memo, forwardRef } from "react";

const NewCollateralVoucher = ({
    serial,
    soldDate,
    customer,
    amount,
    name,
    weight,
    rate,
    allowedMonths,
    soldBy,
    paperSize = 'a4'
}, ref) => {

    const dateParser = new DateParser();
    const { width, height } = paperSizes.find( e => e.name === paperSize ).mm;
    const costPerMonth = Math.round((amount * rate) / 100);

    return (
        <div 
            ref={ref} 
            className="flex flex-col bg-white" 
            style={{width: `${width}mm`, height: `${height}mm`}}
        >
            <div 
                className="flex flex-col bg-white" 
                style={{width: `${width}mm`, height: `${height}mm`}}
            >
                <div className="flex flex-1 flex-col">

                    <div className="flex flex-row border-b border-dashed">
                    
                        {/* for customer */}
                        <div className="p-3 flex flex-col w-2/3 border-r border-dashed space-y-4">
                            
                            <div className="flex flex-row items-center space-x-3 justify-center">
                                <span className="font-myawaddy text-6xl">aumif;pH</span>
                                <span className="font-serif font-bold text-sm pt-3">စိန် ~ ရွှေရတနာဆိုင်</span>
                            </div>

                            <div className="flex flex-row">
                                <div className="flex flex-col flex-1 border-r border-dim-gray space-y-5">
                                    <div className="flex flex-row space-x-2 items-center">
                                        <span className="text-xs font-bold">
                                            ဘောင်ချာနံပါတ် -
                                        </span>
                                        <span className="text-sm font-bold">
                                            { serial }
                                        </span>
                                    </div>
                                    <div className="flex flex-col space-y-3">
                                        <span className="text-xs">
                                            အမည် - { customer.name }
                                        </span>
                                        <span className="text-xs">
                                            နေရပ် - { customer.address }
                                        </span>
                                        { customer.phone ? (
                                            <span className="text-xs">
                                                ဖုန်း - { customer.phone }
                                            </span>
                                        ) : ''}
                                    </div>
                                    <div className="flex flex-col space-y-3">
                                        <span className="text-xs">
                                            ပစ္စည်းအမည် - { name }
                                        </span>
                                        <span className="text-xs">
                                            အလေးချိန် - <WeightStats total={weight}/>
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col flex-1 items-end space-y-5">
                                    <span className="text-xs font-bold">
                                        စတင်ယူသည့်ရက်စွဲ - { dateParser.toString(soldDate) }
                                    </span>

                                    <span className="font-bold font-mono">
                                        ယူငွေ - { new Intl.NumberFormat().format(amount) }
                                    </span>

                                    <div className="flex flex-col space-y-3 items-end">
                                        <span className="text-xs">
                                            တစ်လအတိုးနှုန်း - { rate } ကျပ်တိုး
                                        </span>
                                        <span className="text-xs">
                                            တစ်လအတိုးကျသင့်ငွေ - { new Intl.NumberFormat().format(costPerMonth) } ကျပ်
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className="flex justify-end">
                                <span className="text-2xs">
                                    တာဝန်ခံဝန်ထမ်း - { soldBy.name }
                                </span>
                            </div>

                            <div className="flex flex-col items-center space-y-1">
                                <span className="text-2xs">
                                    * { allowedMonths } လအတွင်း အတိုးရှင်းထားခြင်းမရှိပါက တာဝန်မယူပါ။
                                </span>
                                <span className="text-2xs">
                                    * ပစ္စည်းရွေးသည့်အခါ ဤဘောင်ချာဖြင့်သာလက်ခံပါသည်။
                                </span>
                            </div>

                        </div>

                        {/* to keep in shop */}
                        <div className="flex flex-col space-y-5 p-3 w-1/3">

                            <div className="flex flex-col space-y-3">
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs font-bold">
                                        ဘောင်ချာနံပါတ်
                                    </span>
                                    <span className="text-xs font-bold">
                                        { serial }
                                    </span>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs font-bold">
                                        စတင်ယူသည့်ရက်စွဲ
                                    </span>
                                    <span className="text-xs font-bold">
                                        { dateParser.toString(soldDate) }
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-row justify-center">
                                <span className="font-bold font-mono">
                                    ယူငွေ - { new Intl.NumberFormat().format(amount) }
                                </span>
                            </div>

                            <div className="flex flex-col space-y-3">
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        အမည်
                                    </span>
                                    <span className="text-xs">
                                        { customer.name }
                                    </span>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        နေရပ်
                                    </span>
                                    <span className="text-xs">
                                        { customer.address }
                                    </span>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        ဖုန်း
                                    </span>
                                    { customer.phone ? (
                                        <span className="text-xs">
                                            { customer.phone }
                                        </span>
                                    ) : ''}
                                </div>
                            </div>

                            <div className="flex flex-col space-y-3">
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        ပစ္စည်းအမည်
                                    </span>
                                    <span className="text-xs">
                                        { name }
                                    </span>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        အလေးချိန်
                                    </span>
                                    <span className="text-xs">
                                        <WeightStats total={weight}/>
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-col space-y-3">
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        တစ်လအတိုးနှုန်း
                                    </span>
                                    <span className="text-xs">
                                        { rate } % ({new Intl.NumberFormat().format(costPerMonth)} ကျပ်)
                                    </span>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        အပေါင်ပေးသူ
                                    </span>
                                    <span className="text-xs">
                                        { soldBy.name }
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default memo(forwardRef(NewCollateralVoucher));
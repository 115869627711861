import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import Modal from './Modal';
import moment from "moment";
import { BaseDatePicker } from "../bases";
import DateParser from "../../helper/dateParser";
import DateStats from "../DateStats";
import MortgageVoucherPreviewModal from "./MortgageVoucherPreviewModal";
import axios_custom from "../../axios_config/axios_custom";

const CollateralWithdrawModal = ({ show, item, onWithdrawn, onClose, isOneMonthMinimum = true }) => {

    const dateParser = new DateParser();

    const authUser = useSelector( state => state.app.authUser);
    const [nextPaidInterestId, setNextPaidInterestId] = useState(null);

    const costPerMonth = item ? Math.round((item.amount * item.rate) / 100) : 0;

    const fromDate = item 
        ? ( item.last_paid_date ? new Date(item.last_paid_date) : new Date(item.sold_date) )
        : new Date();    
    const [toDate, setToDate] = useState(new Date());

    //computed
        const isOneMonthExceeded = useMemo( () => {
            if(item){
                let oneMonthAfterSold = new Date(item.sold_date);
                oneMonthAfterSold.setMonth(oneMonthAfterSold.getMonth() + 1);
    
                const todayMoment = moment(new Date());
                const oneMonthAfterSoldMoment = moment(oneMonthAfterSold);        
                return todayMoment.isAfter(oneMonthAfterSoldMoment); 
            }
            return false;
        }, [item, show]);

        const paidDateExceeded = useMemo( () => {
            if(!item){
                return false;
            }
            const lastPaidDate = item.last_paid_date ? moment( new Date(item.last_paid_date) ) : moment( new Date(item.sold_date) );
            const withdrawDate = moment(toDate);
            return !lastPaidDate.isSame(withdrawDate, 'days') && lastPaidDate.isAfter(withdrawDate);
        }, [item, toDate]);

        const duration = useMemo( () => {
            return paidDateExceeded ? dateParser.compareDateDiff(toDate, fromDate) : dateParser.compareDateDiff(fromDate, toDate);
        }, [fromDate, toDate, paidDateExceeded]);

        const totalCost = useMemo( () => {
            const costPerYear = costPerMonth * 12;
            const costPerDay = costPerMonth / 30;

            const total = Math.round((duration.year * costPerYear) + (duration.month * costPerMonth) + (duration.day * costPerDay));
            
            return paidDateExceeded ? total * -1 : total;
        }, [costPerMonth, duration, paidDateExceeded]);

    //mounted 
        useEffect( () => {
            if(show){
                axios_custom.get('next_paid_interest_id')
                .then( res => {
                    setNextPaidInterestId(res.data);
                })
                .catch( () => {
                    setNextPaidInterestId(
                        Math.floor(Math.random() * 9000) + 1000
                    );
                });
            }
        }, [show]);

    //if customer withdraws before 1 month, interest cost will be for 1 month
        useEffect( () => {
            if(item && isOneMonthMinimum && !isOneMonthExceeded){
                let oneMonthAfterSold = new Date(item.sold_date);
                oneMonthAfterSold.setMonth(oneMonthAfterSold.getMonth() + 1);
                setToDate(oneMonthAfterSold);
            } else {
                setToDate(new Date());
            }
        }, [item, isOneMonthMinimum, isOneMonthExceeded]);

    //methods
        const close = useCallback( () => {
            onClose();
        }, []);

        const submitToPreview = () => {
            setShowVoucherPreview(true);
            setVoucherData({
                serial: item.serial + nextPaidInterestId,
                item,
                isWithdraw: true,
                paidDateExceeded: paidDateExceeded,
                amount: totalCost,
                fromDate: fromDate,
                toDate: toDate,
                receivedBy: authUser,
            });
        }

        //voucher modal
            const [showVoucherPreview, setShowVoucherPreview] = useState(false);
            const [voucherData, setVoucherData] = useState(null);

            const onDone = (itemId) => {
                setToDate(new Date());
                onWithdrawn(itemId);
            }

    return (
        <>
            <Modal
                show={show}
                title="အပေါင်ရွေးရန်"
                titleColor="green"
                submitButtonText="ရွေးမယ်"
                onClose={close}
                onSubmit={submitToPreview}
                isSubmitting={false}
            >
                { item ? (
                    <div className="flex flex-col space-y-6 mx-4 my-6">

                        <div className="flex flex-row justify-between">
                            <span className="text-sm text-gray">
                                စတင်ယူသည့်ရက်စွဲ
                            </span>
                            <span className="text-sm text-black">
                                { dateParser.toString(item.sold_date) }
                            </span>
                        </div>

                        <div className="flex flex-col space-y-3">
                            <div className="flex flex-row justify-between">
                                <span className="text-sm text-gray">
                                    အတိုးနှုန်း
                                </span>
                                <span className="text-sm text-black">
                                    {Number(item.rate)} %
                                </span>
                            </div>
                            <div className="flex flex-row justify-between">
                                <span className="text-sm text-gray">
                                    တစ်လ အတိုးသင့်ငွေ
                                </span>
                                <span className="text-sm text-black">
                                    { new Intl.NumberFormat().format(costPerMonth) } ကျပ်
                                </span>
                            </div>
                            <div className="flex flex-row justify-between">
                                <span className="text-sm text-gray">
                                    တစ်ရက် အတိုးသင့်ငွေ
                                </span>
                                <span className="text-sm text-black">
                                    { new Intl.NumberFormat().format(Math.round(costPerMonth / 30)) } ကျပ်
                                </span>
                            </div>
                        </div>

                        {/* divider */}
                        <div className="border-b border-dashed border-b-dim-gray"></div>

                        <div className="flex flex-col space-y-3 justify-center">
                            { item.last_paid_date ? (
                                <div className="flex flex-row justify-between">
                                    <span className="text-sm text-gray">
                                        အတိုးရှင်းပြီးရက်စွဲ
                                    </span>
                                    <div className="flex flex-row items-center space-x-1">
                                        <span className="text-black text-sm">{ dateParser.toString(item.last_paid_date) }</span>
                                        <span className="text-xs text-gray">ထိရှင်းပြီး</span>
                                    </div>
                                </div>
                            ) : ''}

                            { paidDateExceeded ? (
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-sm text-gray">
                                        အပေါင်ရွေးမည့်ရက်
                                    </span>
                                    <div className="flex flex-row items-center justify-center space-x-1">
                                        <BaseDatePicker
                                            position="bottom right"
                                            value={toDate}
                                            trigger={
                                                <button className="bg-white rounded px-2 py-1 border border-dim-gray shadow">
                                                    <span className="text-sm text- font-bold">
                                                        { dateParser.toString(toDate) }
                                                    </span>
                                                </button>
                                            }
                                            disableFutureDates={false}
                                            disableDatesBefore={new Date(item.sold_date)}
                                            onPick={setToDate}
                                            width={270}
                                            offsetX={0}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-sm text-gray">
                                        အတိုးပေးရမည့်ရက်
                                    </span>
                                    <div className="flex flex-row items-center justify-center space-x-2">
                                        <span className="text-sm text- font-bold">
                                            { dateParser.toString(fromDate) }
                                        </span>
                                        <span className="text-soft-black text-xs">
                                            မှ
                                        </span>
                                        <BaseDatePicker
                                            position="bottom right"
                                            value={toDate}
                                            trigger={
                                                <button className="bg-white rounded px-2 py-1 border border-dim-gray shadow">
                                                    <span className="text-sm text- font-bold">
                                                        { dateParser.toString(toDate) }
                                                    </span>
                                                </button>
                                            }
                                            disableFutureDates={false}
                                            disableDatesBefore={ new Date(item.sold_date) }
                                            onPick={setToDate}
                                            width={270}
                                            offsetX={0}
                                        />
                                    </div>
                                </div>
                            )}

                            { paidDateExceeded ? (
                                <div className="flex flex-row space-x-1">
                                    <span className="text-xs text-red">
                                        *
                                    </span>
                                    <div className="flex flex-col space-y-1">
                                        <span className="text-red text-xs">
                                            { dateParser.toString(fromDate)} ထိအတိုးရှင်းပြီးဖြစ်သောကြောင့်
                                        </span>
                                        <div className="flex flex-row">
                                            <span className="text-xs text-red">
                                                <DateStats date={duration}/>
                                            </span>
                                            <span className="text-xs text-red">
                                                စာ ငွေပြန်အမ်းရပါမည်။
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-row space-x-1">
                                        { isOneMonthMinimum && !isOneMonthExceeded ? (
                                            <>
                                                <span className="text-xs text-yellow">
                                                    *
                                                </span>
                                                <div className="flex flex-col space-y-1">
                                                    <span className="text-yellow text-xs">
                                                        တစ်လ မပြည့်သေးသော်လည်း
                                                    </span>
                                                    <span className="text-yellow text-xs">
                                                        ပထမဆုံးလအတွက် ၁ လစာ ကောက်ခံပါသည်။
                                                    </span>
                                                </div>
                                            </>
                                        ) : ''}
                                    </div>
                                    <span className="text-xs text-green font-bold mr-2">
                                        <DateStats date={duration}/>
                                    </span>
                                </div>
                            )}                    
                        </div>

                        { item ? (
                            <div className="bg-milk rounded">
                                <div className="flex flex-row items-center space-x-5 p-3">
                                    <div className="flex flex-col space-y-2 flex-1 items-end text-sm">
                                        <div className="flex items-center text-gray h-6">
                                            <span>ယူငွေ</span>
                                        </div>
                                        <div className="flex flex-row space-x-1 items-center text-gray h-6">
                                            <span>
                                                <DateStats date={duration}/>
                                            </span>
                                            <span>
                                                စာ{ paidDateExceeded ? 'ငွေပြန်အမ်းရန်' : 'အတိုး'}
                                            </span>
                                        </div>

                                        {/* divider */}
                                        <div className="pt-1"></div>

                                        <div className="flex text-gray items-center h-6 font-bold">
                                            <span>စုစုပေါင်းသင့်ငွေ</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col space-y-2 justify-center items-end font-mono">
                                        <div className="flex items-center h-6">
                                            <span>{ new Intl.NumberFormat().format(item.amount) }</span>
                                        </div>
                                        <div className="flex items-center h-6">
                                            <span> { paidDateExceeded ? '-' : '+' } { new Intl.NumberFormat().format(Math.abs(totalCost)) }</span>
                                        </div>

                                        {/* divider */}
                                        <div className="border-b border-dashed border-b-gray w-full pt-1"></div>

                                        <div className="flex items-center text-yellow font-bold h-6">
                                            <span>{ new Intl.NumberFormat().format(totalCost + item.amount) }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                ) : ''}
            </Modal>
            <MortgageVoucherPreviewModal
                type="pay_interest"
                show={showVoucherPreview}
                data={voucherData}
                onDone={onDone}
                onClose={() => setShowVoucherPreview(false)}
            />
        </>
    );
};

export default memo(CollateralWithdrawModal);

import React, { cloneElement, memo, useEffect, useMemo, useRef, useState } from 'react'
import Modal from './Modal'
import paperSizes from '../../helper/paperSizes';
import { SubmitButton } from '../inputs';
import * as htmlToImage from 'html-to-image';
import { NewCollateralVoucher, PayInterestVoucher, WithdrawVoucher } from '../vouchers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faX } from '@fortawesome/free-solid-svg-icons';
import axios_custom from '../../axios_config/axios_custom';
import useStoreHelper from '../../hooks/useStoreHelper';
import { useReactToPrint } from 'react-to-print';

const VoucherError = ({size, msg}) => {
    return (
        <div 
            className="flex items-center justify-center border border-dim-gray"
            style={{
                width: size.width,
                height: size.height
            }}
        >
            <span className="text-white text-xs">
                {msg}
            </span>
        </div>
    )
}

const MortgageVoucherPreviewModal = ({
    tasks = ['sell', 'print'],
    type = 'new_collateral', //new_collateral, pay_interest
    show,
    data,
    onClose,
    onDone,
}) => {
    const voucherRef = useRef(null);

    const { handleError } = useStoreHelper();

    const [paperSize, setPaperSize] = useState('a4');
    const [voucherImage, setVoucherImage] = useState(null);
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [finishedTasks, setFinishedTasks] = useState([]);
    const [addedData, setAddedData] = useState(null);
    
    //computed image size
        const imageSize = useMemo( () => {
            const currentPaper = paperSizes.find( e => e.name === paperSize ).mm;
            return {
                width: currentPaper.width + 130,
                height: currentPaper.height + 130
            }
        }, [paperSize]);
    
    //watchers
        //voucher component
        const voucher = useMemo( () => {
            if(!show || !data){ 
                return null; 
            }
            const voucherComponent = type === 'pay_interest' 
            ? ( data.isWithdraw ? <WithdrawVoucher/> : <PayInterestVoucher/> )
            : <NewCollateralVoucher/>;

            return cloneElement(voucherComponent, { 
                    ref: voucherRef, 
                    paperSize: paperSize,
                    ...data
                });
        }, [show, type, paperSize, data]);

        //genereate image
        useEffect( () => {
            if(voucher){
                //added timout to prevent caputuring image before voucher component is ready. 
                setTimeout(() => {
                    const voucherComponent = voucherRef.current;
                    htmlToImage.toPng(voucherComponent)
                    .then( image => {
                        setVoucherImage(image);
                    })
                    .catch( () => {
                        setVoucherImage({error: true});
                    });
                }, voucherImage ? 0 : 1000);
            }
        }, [voucher]);

        //tasks completion
        useEffect( () => {
            if(tasks.every( e => finishedTasks.includes(e))){
                onDone(addedData);
                close();
            }
        }, [finishedTasks]);

    //methods
        const sell = (sellOnly) => {
            setIsSubmitting(true);

            const source = type === 'new_collateral' ? 'collaterals' : 'paid_interests';
            const formData = new FormData();
            
            if(type === 'new_collateral'){
                formData.append('serial', data.serial);
                formData.append('sold_date', data.soldDate.toISOString());
                formData.append('expiry_date', data.expiryDate.toISOString());
                formData.append('customer_name', data.customer.name);
                formData.append('customer_address', data.customer.address);
                formData.append('customer_phone', data.customer.phone);
                formData.append('amount', data.amount);
                formData.append('name', data.name);
                formData.append('weight', JSON.stringify(data.weight));
                formData.append('description', data.description);
                formData.append('rate', data.rate);
                formData.append('allowed_months', data.allowedMonths);
                formData.append('user', data.soldBy.id);
            } else {
                formData.append('serial', data.serial);
                formData.append('id', data.item.id);
                formData.append('amount', data.amount);
                formData.append('is_withdraw', data.isWithdraw);
                formData.append('from_date', data.fromDate.toISOString());
                formData.append('to_date', data.toDate.toISOString());
                formData.append('user', data.receivedBy.id);
            }
            
            axios_custom.post(source, formData)
            .then( res => {
                setIsSubmitting(false);
                setAddedData( type === 'new_collateral' ? res.data.new_collateral_id : res.data.id );
                setFinishedTasks( prev => sellOnly ? [...prev, 'sell', 'print'] : [...prev, 'sell']);
            })
            .catch( error => {
                setIsSubmitting(false);
                setFinishedTasks( prev => sellOnly ? [...prev, 'sell', 'print'] : [...prev, 'sell']);
                handleError(error);
            });
        }

        const print = useReactToPrint({
            content: () => voucherRef.current,
            onAfterPrint: () =>{
                setFinishedTasks(prev => [...prev, 'print']);
            },
            onPrintError: () => {
                setFinishedTasks(prev => [...prev, 'print']);
            }
        });

        const sellAndPrint = () => {
            sell(false);
            print();
        }

        const close = () => {
            setFinishedTasks([]);
            onClose();
        }

    return (
        <>
            <Modal
                show={show}
                withCard={false}
            >
                <div className="flex flex-col space-y-5 flex-1 w-11/12 max-h-screen overflow-auto py-10">

                    {/* voucher image */}
                    <div className="flex-1 flex flex-col items-center justify-end">
                        { voucherImage ? (
                            voucherImage.error ? (
                                <VoucherError
                                    size={imageSize}
                                    msg="Voucher Failed to Load"
                                />
                            ) : (
                                <div className="flex flex-col space-y-2 items-center">
                                    <span className="text-white text-xs">
                                        Preview
                                    </span>
                                    <img
                                        src={voucherImage}
                                        style={{
                                            width: imageSize.width,
                                        }}
                                    />
                                </div>
                            )
                            
                        ) : (
                            <VoucherError
                                size={imageSize}
                                msg="Loading..."
                            />
                        )}
                        
                    </div>

                    {/* buttons section */}
                    <div className="flex h-1/4 flex-col m-1 items-center">
                            
                        <div className="flex flex-col space-y-2 w-full sm:max-w-lg">
                            {/* paper sizes */}
                            <div className="flex flex-row h-8 space-x-3">
                                { paperSizes.map( (paper, index) => (
                                    <button 
                                        key={index}
                                        className={`flex-1 flex flex-row space-x-2 items-center justify-center rounded ${ paper.name === paperSize ? 'bg-dim-sky' : 'bg-dim-white'}`} 
                                        onClick={() => setPaperSize(paper.name)}
                                    >
                                        <div className={`rounded-full border p-1 ${ paper.name === paperSize ? 'bg-soft-sky border-sky' : 'bg-white border-dim-gray'}`}></div>
                                        <span className="text-xs">{paper.label}</span>
                                    </button>
                                ))}
                            </div>

                            <div className="flex flex-row justify-between h-12 space-x-2">
                                
                                { (tasks.length < 2) ? (
                                    <SubmitButton
                                        title="Cancel"
                                        color="soft-gray"
                                        textColor="white"
                                        height="12"
                                        radius="rounded-lg"
                                        disabled={isSubmitting}
                                        onClick={close}
                                    />
                                ) : ''}

                                { (tasks.includes('sell')) ? (
                                    <SubmitButton
                                        title={
                                            <div className="flex flex-row space-x-2 items-center">
                                                { tasks.includes('print') ? (
                                                    <FontAwesomeIcon icon={faX} size="xs" color="red"/>
                                                ) : ''}
                                                <span className={ tasks.includes('print') ? 'text-xs' : ''}>
                                                    { tasks.includes('print') ? 'ဘောင်ချာမထုတ်သေးပါ' : 'Confirm' }
                                                </span>
                                            </div>
                                        }
                                        color={tasks.includes('print') ? 'milk' : 'green'}
                                        height="12"
                                        textColor={tasks.includes('print') ? 'black' : 'white'}
                                        radius="rounded-lg"
                                        activated={isSubmitting}
                                        disabled={isSubmitting}
                                        onClick={ () => sell(true)}
                                    />
                                ) : ''}

                                { (tasks.includes('print')) ? (
                                    <SubmitButton
                                        title={
                                            <div className="flex flex-row space-x-2 items-center">
                                                <FontAwesomeIcon icon={faPrint}/>
                                                <span className={ tasks.includes('sell') ? 'text-xs' : ''}>
                                                    { tasks.includes('sell') ? 'ဘောင်ချာထုတ်မယ်' : 'Print' }
                                                </span>
                                            </div>
                                        }
                                        color={tasks.includes('sell') ? 'green' : 'blue'}
                                        height="12"
                                        radius="rounded-lg"
                                        activated={isSubmitting}
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            tasks.includes('sell') ? sellAndPrint() : print();
                                        }}
                                    />
                                ) : ''}
                            </div>

                            { tasks.includes('sell') && tasks.includes('print') ? (
                                <div className="flex flex-row justify-between h-12 space-x-2">
                                    <SubmitButton
                                        title="Cancel"
                                        color="soft-gray"
                                        textColor="white"
                                        height="12"
                                        radius="rounded-lg"
                                        activatedColor="soft-gray"
                                        disabled={isSubmitting}
                                        onClick={close}
                                    />
                                </div>
                            ) : ''}

                        </div>

                    </div>
                </div>
            </Modal>

            {/* render voucher component */}
            { voucher ? voucher : ''}
        </>
    )
}

export default memo(MortgageVoucherPreviewModal);
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { CounterInput, DatePicker, Input, TagInput, WeightInput } from "../inputs";
import Modal from "./Modal";
import Weight from "../../helper/weight";
import DateParser from "../../helper/dateParser";
import MortgageVoucherPreviewModal from "./MortgageVoucherPreviewModal";
import axios_custom from "../../axios_config/axios_custom";

const generateSerial = (newCollateralId) => {
    const randomLetters = String.fromCharCode(65 + Math.floor(Math.random() * 26)) +
                        String.fromCharCode(65 + Math.floor(Math.random() * 26));
    
    const paddedId = String(newCollateralId).padStart(3, '0');
    
    return randomLetters.toUpperCase() + paddedId;
}

const NewCollateralModal = ({ show, onAdded, onClose }) => {

    const dateParser = new DateParser();
    const weight = new Weight();
    const authUser = useSelector( state => state.app.authUser);

    const [errorCheckable, setErrorCheckable] = useState (false);

    const defaultInputValues = {
        date: new Date(),
        amount: '',
        rate: 2.5,
        allowedMonths: 5,
        customer_name: '',
        customer_address: '',
        customer_phone: '',
        name: '',
        description: ''
    }

    const [collateralWeight, setCollateralWeight] = useState(weight.defaultValue);
    const [collateralWeightHasError, setCollateralWeightHasError] = useState(true);

    const [inputs, setInputs] = useState(defaultInputValues);

    const [nextCollateralId, setNextCollateralId] = useState(null);
    const [showVoucherPreview, setShowVoucherPreview] = useState(false);
    const [voucherData, setVoucherData] = useState(null);

    //mounted 
        useEffect( () => {
            if(show){
                axios_custom.get('next_collateral_id')
                .then( res => {
                    setNextCollateralId(res.data);
                })
                .catch( () => {
                    setNextCollateralId(
                        Math.floor(Math.random() * 9000) + 1000
                    );
                });
            }
        }, [show]);

    //computed
        const cost = useMemo( () => {
            const perMonth = Math.round((inputs.amount * inputs.rate) / 100);
            const perDay = Math.round(perMonth / 30);
            return { perMonth, perDay };
        }, [inputs.rate, inputs.amount]);

        const expiryDate = useMemo( () => {
            const date = new Date(inputs.date);
            date.setMonth(date.getMonth() + Number(inputs.allowedMonths));
            return date;
        }, [inputs.date, inputs.allowedMonths]);

        const inputErrors = useMemo( () => {
            const errors = [];

            inputs.customer_name === '' && errors.push('customer_name');
            inputs.customer_address === '' && errors.push('customer_address');
            inputs.name === '' && errors.push('name');
            inputs.amount === '' && errors.push('amount');
            inputs.rate === '' && errors.push('rate');

            if(Number(inputs.allowedMonths) <= 0 || Number(inputs.allowedMonths) > 1000){
                errors.push('allowed_months');
            }

            return errors;
        }, [inputs.customer_name, inputs.customer_address, inputs.name, inputs.amount, inputs.rate, inputs.allowedMonths]);

    //methods
        const handleCollateralWeight = useCallback( ( e ) => {
            setCollateralWeight(e.weight);
            setCollateralWeightHasError(e.hasError);
        }, []);

        const close = useCallback( () => {
            setInputs(defaultInputValues);
            setErrorCheckable(false);
            setCollateralWeight(weight.defaultValue);
            onClose();
        }, []);

        const submitToPreview = () => {
            setErrorCheckable(true); 

            if(inputErrors.length === 0 && !collateralWeightHasError) {
                setShowVoucherPreview(true);
                setVoucherData({
                    serial: generateSerial(nextCollateralId),
                    soldDate: inputs.date,
                    expiryDate: expiryDate,
                    customer: {
                        name: inputs.customer_name,
                        address: inputs.customer_address,
                        phone: inputs.customer_phone,
                    },
                    amount: inputs.amount,
                    name: inputs.name,
                    weight: collateralWeight,
                    description: inputs.description,
                    rate: inputs.rate,
                    allowedMonths: inputs.allowedMonths,
                    soldBy: authUser
                });
            }
        }

        const onDone = (newCollateralData) => {
            setErrorCheckable(false);
            setInputs(defaultInputValues);
            onAdded(newCollateralData);
        }

return (
    <>
        <Modal
            show={show}
            title="အပေါင်အသစ်ပေးရန်"
            titleColor="sky"
            submitButtonText="Preview Voucher"
            onClose={close}
            onSubmit={submitToPreview}
            isSubmitting={false}
        >
            <div className="flex flex-col space-y-4 mx-3 mt-4 mb-8">

                <span className="font-bold">Customer အချက်အလက်</span>

                <Input
                    title="အမည်"
                    type="text"
                    placeholder="eg. ဦးမောင်မောင်"
                    value={inputs.customer_name}
                    onChange={ text => setInputs({ ...inputs, customer_name: text })}
                    onPressEnterKey={submitToPreview}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('customer_name'),
                            msg: 'အပေါင်ထားသူအမည်ထည့်ပါ!'
                        }
                    ]}
                />

                <Input
                    title="လိပ်စာ"
                    type="text"
                    placeholder="eg. ရန်ကုန်"
                    value={inputs.customer_address}
                    onChange={ text => setInputs({ ...inputs, customer_address: text })}
                    onPressEnterKey={submitToPreview}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('customer_address'),
                            msg: 'အပေါင်ထားသူလိပ်စာထည့်ပါ!'
                        }
                    ]}
                />

                <Input
                    title="Phone"
                    type="number"
                    placeholder="eg. 09123456789"
                    value={inputs.customer_phone}
                    warningMsges={['မထည့်ပဲထားခဲ့လဲရသည်။']}
                    onChange={ text => setInputs({ ...inputs, customer_phone: text })}
                    onPressEnterKey={submitToPreview}
                />

                {/* divider */}
                <div className="border-b border-b-dim-gray"></div>

                <span className="font-bold pb-2">အပေါင်ပစ္စည်း အချက်အလက်</span>

                <Input
                    title="ပစ္စည်းအမည်"
                    type="text"
                    placeholder="eg. ကျောက်လက်စွပ်"
                    value={inputs.name}
                    onChange={ text => setInputs({ ...inputs, name: text })}
                    onPressEnterKey={submitToPreview}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('name'),
                            msg: 'အပေါင်ပစ္စည်းအမည်ထည့်ပါ!'
                        }
                    ]}
                />

                <WeightInput
                    title="အလေးချိန်"
                    value={collateralWeight}
                    onChange={handleCollateralWeight}
                    onPressEnterKey={submitToPreview}
                    disableResponsive={true}
                    nullable={true}
                />

                {/* divider */}
                <div className="border-b border-b-dim-gray"></div>

                <DatePicker
                    title="စတင်ယူသည့်ရက်စွဲ"
                    disableFutureDates={false}
                    disablePastDates={false}
                    value={inputs.date}
                    onChange={ newDate => setInputs({...inputs, date: newDate })}
                />

                <CounterInput
                    title="အများဆုံးခွင့်ပြုသည့်လ"
                    min="1"
                    max="1000"
                    value={inputs.allowedMonths}
                    onChange={ newValue => setInputs({...inputs, allowedMonths: newValue })}
                />

                <span className="text-soft-gray text-xs">
                    သက်တမ်းကုန်ဆုံးရက် - { dateParser.toString(expiryDate)}
                </span>

                <TagInput
                    title="ယူငွေ"
                    type="number"
                    placeholder="eg. 99999"
                    tagLabel="ကျပ်"
                    value={inputs.amount}
                    onChange={ text => setInputs({ ...inputs, amount: text })}
                    onPressEnterKey={submitToPreview}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('amount'),
                            msg: 'အပေါင်ယူသည့်ငွေပမာဏထည့်ပါ!'
                        }
                    ]}
                />

                <TagInput
                    title="အတိုးနှုန်း"
                    type="decimal"
                    placeholder=""
                    tagLabel="ကျပ်တိုး (%)"
                    value={inputs.rate}
                    onChange={ text => setInputs({ ...inputs, rate: text })}
                    onPressEnterKey={submitToPreview}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('rate'),
                            msg: 'အတိုးနှုန်းထည့်ပါ!'
                        }
                    ]}
                />

                <span className="text-soft-gray text-xs">
                    တစ်လအတိုးသင့်ငွေ - { new Intl.NumberFormat().format(cost.perMonth) } ကျပ်
                </span>

                <span className="text-soft-gray text-xs">
                    တစ်ရက်အတိုးသင့်ငွေ - { new Intl.NumberFormat().format(cost.perDay) } ကျပ်
                </span>

                <Input
                    title="မှတ်ချက်"
                    type="textarea"
                    placeholder="Write something..."
                    value={inputs.description}
                    warningMsges={['မထည့်ပဲထားခဲ့လဲရသည်။']}
                    onChange={ text => setInputs({ ...inputs, description: text })}
                    onPressEnterKey={submitToPreview}
                />
            </div>
        </Modal>
        <MortgageVoucherPreviewModal
            type="new_collateral"
            show={showVoucherPreview}
            data={voucherData}
            onDone={onDone}
            onClose={() => setShowVoucherPreview(false)}
        />
    </>
);
};

export default memo(NewCollateralModal);

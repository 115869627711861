import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import Modal from './Modal';
import { CounterInput } from "../inputs";
import DateParser from "../../helper/dateParser";
import MortgageVoucherPreviewModal from "./MortgageVoucherPreviewModal";
import axios_custom from "../../axios_config/axios_custom";

const PayInterestModal = ({ show, item, onPaid, onClose }) => {

    const dateParser = new DateParser();

    const authUser = useSelector( state => state.app.authUser);
    const [nextPaidInterestId, setNextPaidInterestId] = useState(null);

    const costPerMonth = item ? Math.round((item.amount * item.rate) / 100) : 0;
    const fromDate = item 
        ? (item.last_paid_date ? new Date(item.last_paid_date) : new Date(item.sold_date) )
        : new Date();

    const [payMonthCount, setPayMonthCount] = useState(1);

    const hasError = useMemo( () => {
        return !payMonthCount || Number(payMonthCount) <= 0 || Number(payMonthCount) > 1000
    }, [payMonthCount]);

    const payData = useMemo( () => {
        const totalCost = costPerMonth * payMonthCount;
        const toDate = new Date(fromDate);
        toDate.setMonth(toDate.getMonth() + Number(payMonthCount));

        return {
            totalCost,
            toDate
        }
    }, [payMonthCount, costPerMonth, fromDate]);

    //mounted 
    useEffect( () => {
        if(show){
            axios_custom.get('next_paid_interest_id')
            .then( res => {
                setNextPaidInterestId(res.data);
            })
            .catch( () => {
                setNextPaidInterestId(
                    Math.floor(Math.random() * 9000) + 1000
                );
            });
        }
    }, [show]);

    //methods
        const close = useCallback( () => {
            setPayMonthCount(1);
            onClose();
        }, []);

        const submitToPreview = () => {
            if(!hasError) {
                setShowVoucherPreview(true);
                setVoucherData({
                    serial: item.serial + nextPaidInterestId,
                    item,
                    isWithdraw: false,
                    amount: payData.totalCost,
                    fromDate,
                    toDate: payData.toDate,
                    receivedBy: authUser,
                });
            }
        }

        //voucher modal
            const [showVoucherPreview, setShowVoucherPreview] = useState(false);
            const [voucherData, setVoucherData] = useState(null);

            const onDone = (itemId) => {
                setPayMonthCount(1);
                onPaid(itemId);
            }

    return (
        <>
            <Modal
                show={show}
                title="အတိုးပေးရန်"
                titleColor="sky"
                submitButtonText="Pay"
                onClose={close}
                onSubmit={submitToPreview}
                isSubmitting={false}
            >
                <div className="flex flex-col space-y-5 mx-3 my-5">

                    <div className="flex items-center justify-center h-14 mx-5 rounded bg-dim-sky">
                        <span className="text-soft-black font-bold font-mono">
                            သင့်ငွေ - { new Intl.NumberFormat().format(payData.totalCost) } ကျပ်
                        </span>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="flex flex-row space-x-2">
                            <span className="text-soft-black font-bold">
                                { dateParser.toString(fromDate) }
                            </span>
                            <span className="text-soft-black text-sm">
                                မှ
                            </span>
                            <span className="text-soft-black font-bold">
                                { dateParser.toString(payData.toDate) }
                            </span>
                            <span className="text-soft-black text-sm">
                                ထိ
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="flex-1 flex flex-col space-y-2 items-center justify-center">
                            <span className="text-gray text-xs font-bold">
                                တစ်လအတိုးနှုန်း
                            </span>
                            <span className="text-sky text-sm">
                                { new Intl.NumberFormat().format(costPerMonth) } ကျပ် 
                            </span>
                        </div>
                        <div className="flex-1">
                            <CounterInput
                                title="ပေးလိုသည့် လ အရေအတွက်"
                                min="1"
                                max="1000"
                                value={payMonthCount}
                                onChange={setPayMonthCount}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <MortgageVoucherPreviewModal
                type="pay_interest"
                show={showVoucherPreview}
                data={voucherData}
                onDone={onDone}
                onClose={() => setShowVoucherPreview(false)}
            />
        </>
    );
};

export default memo(PayInterestModal);

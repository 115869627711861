import React, { memo, useMemo } from 'react';
import NavToggles from './NavToggles';
import { months, years } from '../../helper/monthsAndYears';
import { faCaretDown, faCaretUp, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import useNavigation from '../../hooks/useNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBoxList, PopUpPicker } from '../../components/inputs';
import { DoubleInput, DoubleInputWithOptions, SingleInput, DatePicker, MainSearchInput } from './inputs';

const MobileNav = ({
    navType,
    enableAllSearch,
    enabledSearches,
    disableNavChoice,
    countBy, 
    fixedTop,

    defaultInputFilters,
    users,
    options,
    currentGroup,
    enableAdvanceSearch,
    showSearchForm, setShowSearchForm,
    highlightedDates,

    inputFilters, setInputFilters,
    group, setGroup,
    user, setUser,
    year, setYear,
    month, setMonth,
    setMinWeightUnit,
    setMaxWeightUnit,
    checked_categories,
    checked_sizes,

    changeCategories, 
    changeSizes,
    search,
    searchAll
}) => {

    const groups = useSelector( state => state.app.groups);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const navigation = useNavigation();

    const weightUnits = [
        {
            id: 1,
            name: 'ကျပ်',
            value: 'kyat'
        },
        {
            id: 2,
            name: 'ပဲ',
            value: 'pel'
        },
        {
            id: 3,
            name: 'ရွေး',
            value: 'yway'
        }
    ];

    const userDataForPopUpPicker = useMemo( () => {
        const data = enableAllSearch ? users : users.filter( e => e.id !== null );
        return data.map( e => {
            return {
                id: e.id,
                name: e.nickname
            }
        })
    }, [users]);

    return (
        <div className={`z-20 top-0 left-0 right-0 ${ fixedTop ? 'fixed' : '' }`}>

            {/* main content */}
            <div className="bg-soft-black border-b border-b-soft-black">
                <div className="flex flex-col mx-3">

                    {/* 1st row */}
                    <div className="flex flex-row items-center h-16">

                        <div>
                            <button 
                                className="flex items-center"
                                onClick={ () => navigation.navigate('sale') }
                            >
                                <span 
                                    className={`${ (currentRoute.name === 'sale' ) ? 'font-selfie text-3xl text-white' : 'text-sm text-milk' }`}
                                >
                                    { (currentRoute.name === 'sale' ) ? 'Kaung San' : currentRoute.title }
                                </span>
                            </button>
                        </div>

                        <div className="flex-1 flex justify-end">
                            <NavToggles/>
                        </div>

                    </div>

                    {/* 2nd row */}
                    <div className="flex flex-row h-12 rounded items-center overflow-hidden">

                        { navType === 'groups' ? (
                            <PopUpPicker
                                triggerClassName={`h-full flex justify-center items-center px-3 rounded-l bg-${currentGroup.color} ${!enableAdvanceSearch && 'flex-1 rounded-r'}`}
                                disabled={disableNavChoice}
                                data={ enableAllSearch ? groups : groups.filter( e => e.id !== null )}
                                value={group}
                                onPick={setGroup}
                                theme="light"
                                countBy={countBy}
                                position={ enableAdvanceSearch ? 'bottom left' : 'bottom center'}
                            />
                        ) : ''}

                        { navType === 'users' ? (
                            <PopUpPicker
                                triggerClassName={`h-full flex justify-center items-center px-3 rounded-l border bg-soft-black border-gray ${!enableAdvanceSearch && 'flex-1 rounded-r'}`}
                                disabled={disableNavChoice}
                                data={userDataForPopUpPicker}
                                value={user}
                                onPick={setUser}
                                countBy={countBy}
                                position={ enableAdvanceSearch ? 'bottom left' : 'bottom center'}
                            />
                        ) : ''}

                        { navType === 'calendar' ? (
                            <>  
                                <PopUpPicker
                                    triggerClassName="flex h-full bg-soft-black justify-center items-center px-3 rounded-l border border-green"
                                    disabled={disableNavChoice}
                                    theme="dark"
                                    data={years.map( year => {
                                        return {
                                            id: year,
                                            name: year
                                        }
                                    })}
                                    value={year}
                                    onPick={setYear}
                                    position="bottom left"
                                />
                                
                                <PopUpPicker
                                    triggerClassName="flex flex-1 bg-soft-black h-full justify-center items-center px-3 rounded-r border border-cyan"
                                    theme="dark"
                                    disabled={disableNavChoice}
                                    data={[
                                        {
                                            id: null,
                                            name: 'တစ်နှစ်လုံးစာ'
                                        },
                                        ...months
                                    ]}
                                    value={month}
                                    onPick={setMonth}
                                />
                            </>

                        ) : (
                            enableAdvanceSearch ? (
                                <MainSearchInput
                                    type={ navType === 'voucher_number_input' ? 'text' : 'number'}
                                    placeholder={ navType === 'voucher_number_input' ? 'ဘောင်ချာနံပါတ်ဖြင့်ရှာရန်...' : 'Search ID' }
                                    value={inputFilters.id}
                                    onPressEnterKey={ () => search('id') }
                                    disabled={!enabledSearches.includes('id')}
                                    onChange={ text => {
                                        const newId = navType === 'voucher_number_input' ? text.toUpperCase() : text;
                                        setInputFilters({...defaultInputFilters, id: newId})
                                    }}
                                    onSearch={ () => search('id')}
                                    onRefresh={searchAll}
                                />
                            ) : ''
                        )}
                    </div>

                    {/* 3th row */}
                    <div className="flex pt-1">
                        { enableAdvanceSearch ? (
                            <button
                                className="flex flex-1 items-center justify-center h-12 rounded"
                                onClick={() => setShowSearchForm(prev => !prev)}
                            >
                                <FontAwesomeIcon icon={showSearchForm ? faCaretUp : faCaretDown} size="xl" color="white"/>
                            </button>
                        ) : ''}
                    </div>
                    

                    <div className={`bg-soft-black overflow-auto transition-height duration-200 ${ showSearchForm ? 'h-96' : 'h-0' }`}>
                        <div className="flex flex-col space-y-4 my-2 mb-10">
                            {/* date */}
                            {enabledSearches.includes('date') ? (
                                <DatePicker
                                    title="နေ့စွဲ"
                                    value={inputFilters.date}
                                    highlightedDates={highlightedDates}
                                    onChange={ newDate => setInputFilters({...inputFilters, date: newDate})}
                                    onClick={search}
                                />
                            ) : ''}

                            {/* word */}
                            {enabledSearches.includes('word') ? (
                                <SingleInput
                                    title="ပစ္စည်းအမည်"
                                    type="text"
                                    placeholder="eg. မောင်းကွင်း"
                                    value={inputFilters.word}
                                    onPressEnterKey={search}
                                    onChange={ text => setInputFilters({...inputFilters, word: text })}
                                    onClick={search}
                                />
                            ) : ''}

                            {/* customer */}
                            {enabledSearches.includes('customer') ? (
                                <>
                                    {/* customer name */}
                                    <SingleInput
                                        title="ဝယ်သူအမည်"
                                        type="text"
                                        placeholder="eg. ဒေါ်သီတာ"
                                        value={inputFilters.customer_name}
                                        onPressEnterKey={search}
                                        onChange={ text => setInputFilters({...inputFilters, customer_name: text })}
                                        onClick={search}
                                    />
                                    {/* customer address */}
                                    <SingleInput
                                        title="ဝယ်သူလိပ်စာ"
                                        type="text"
                                        placeholder="eg. ရွှေတောင်"
                                        value={inputFilters.customer_address}
                                        onPressEnterKey={search}
                                        onChange={ text => setInputFilters({...inputFilters, customer_address: text })}
                                        onClick={search}
                                    />
                                    {/* customer phone */}
                                    <SingleInput
                                        title="ဝယ်သူဖုန်းနံပါတ်"
                                        type="number"
                                        placeholder="eg. 09123456789"
                                        value={inputFilters.customer_phone}
                                        onPressEnterKey={search}
                                        onChange={ text => setInputFilters({...inputFilters, customer_phone: text })}
                                        onClick={search}
                                    />
                                </>
                            ) : ''}

                            {/* price */}
                            {enabledSearches.includes('price') ? (
                                <DoubleInput
                                    title="စျေးနှုန်း Range"
                                    type="number"
                                    left={{
                                        value: inputFilters.min_price,
                                        placeholder: 'အနည်းဆုံး',
                                        onChange: text => setInputFilters({...inputFilters, min_price: text})
                                    }}
                                    right={{
                                        value: inputFilters.max_price,
                                        placeholder: 'အများဆုံး',
                                        onChange: text => setInputFilters({...inputFilters, max_price: text})
                                    }}
                                    onPressEnterKey={search}
                                    onClick={search}
                                />
                            ) : ''}

                            {/* type */}
                            { options.categories.length !== 0  && enabledSearches.includes('type') ? (
                                <div className="flex flex-col space-y-2">
                                    <span className="text-white text-xs">အမျိုးအစား</span>
                                    <CheckBoxList
                                        containerClassName="flex-1 bg-white rounded"
                                        rowClassName="h-14"
                                        data={options.categories}
                                        values={checked_categories}
                                        onSelect={changeCategories}
                                    />
                                </div>
                            ) : ''}

                            {/* size */}
                            { options.sizes.length !== 0  && enabledSearches.includes('size') ? (
                                <div className="flex flex-col space-y-2">
                                    <span className="text-white text-xs">{ currentGroup.size_name }</span>
                                    <CheckBoxList 
                                        containerClassName="flex-1 bg-white rounded"
                                        rowClassName="h-14"
                                        data={options.sizes}
                                        values={checked_sizes}
                                        onSelect={changeSizes}
                                    />
                                </div>
                            ) : ''}

                            {/* weight */}
                            {enabledSearches.includes('weight') ? (
                                <DoubleInputWithOptions
                                    title="အလေးချိန် Range"
                                    type="number"
                                    left={{
                                        value: inputFilters.min_weight,
                                        placeholder: 'အနည်းဆုံး',
                                        onChange: text => setInputFilters({...inputFilters, min_weight: text}),
                                        option: {
                                            defaultValue: 2,
                                            data: weightUnits,
                                            onChange: weightUnitId => setMinWeightUnit(weightUnits.find( e => e.id === weightUnitId ).value)
                                        },
                                    }}
                                    right={{
                                        value: inputFilters.max_weight,
                                        placeholder: 'အများဆုံး',
                                        onChange: text => setInputFilters({...inputFilters, max_weight: text}),
                                        option: {
                                            defaultValue: 2,
                                            data: weightUnits,
                                            onChange: weightUnitId => setMaxWeightUnit(weightUnits.find( e => e.id === weightUnitId).value)
                                        },
                                    }}
                                    onPressEnterKey={search}
                                    onClick={search}
                                />
                            ) : ''}

                            {/* user */}
                            { (enabledSearches.includes('user') && navType !== 'users' ) ? (
                                <div className="flex flex-col space-y-2">
                                    <span className="text-white text-xs">ဝန်ထမ်း</span>
                                    <div className="flex flex-row h-11 bg-white rounded">
                                        <PopUpPicker
                                            position="top center"
                                            triggerClassName="flex-1 flex justify-center items-center px-4 bg-white rounded-l"
                                            disabled={false}
                                            data={users}
                                            textColor="black"
                                            nullable={false}
                                            value={user}
                                            onPick={setUser}
                                        />
                                        <button
                                            className="px-3 bg-sky items-center justify-center rounded-r"
                                            onClick={search}
                                        >
                                            <div className="flex flex-row items-center justify-center space-x-1">
                                                <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
                                                <span className="text-white text-sm">Find</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            ) : ''}
                            
                        </div>
                    </div>

                </div>
            </div>

            {/* some space to close searchForm */}
            { showSearchForm ? (
                <div
                    className="h-screen opacity-0 bg-none"
                    onClick={ () => setShowSearchForm(false)}    
                >
                </div>
            ) : ''}

        </div>
    );
};

export default memo(MobileNav);

import { 
    faCalculator,
    faChartLine,
    faCubesStacked,
    faFileInvoiceDollar,
    faFileSignature,
    faHandHoldingDollar,
    faMoneyBillTransfer,
    faPenToSquare,
    faReceipt, 
    faSackDollar, 
    faScaleUnbalanced,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { faOpencart } from '@fortawesome/free-brands-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import LoginScreen from '../screens/LoginScreen';
import SaleScreen from '../screens/SaleScreen';
import SoldScreen from '../screens/SoldScreen';
import AddedScreen from '../screens/AddedScreen';
import AddImageScreen from '../screens/AddImageScreen';
import AddRecordScreen from '../screens/AddRecordScreen';
import FinanceScreen from '../screens/FinanceScreen';
import DebtScreen from '../screens/DebtScreen';
import CollateralScreen from '../screens/CollateralScreen';
import EditRequestedScreen from '../screens/EditRequestedScreen';
import FormScreen from '../screens/FormScreen';
import ImageAddedScreen from '../screens/ImageAddedScreen';
import ManageRequestScreen from '../screens/ManageRequestScreen';
import NetWorthScreen from '../screens/NetWorthScreen';
import ReportScreen from '../screens/ReportScreen';
import TraderWeightScreen from '../screens/TraderWeightScreen';
import IndivitualSoldScreen from '../screens/IndividualSoldScreen';
import CalculatorScreen from '../screens/CalculatorScreen';

import GroupsScreen from '../screens/GroupsScreen';
import GroupFormScreen from '../screens/GroupFormScreen';
import CategoriesScreen from '../screens/CategoriesScreen';
import CategoryDetailsScreen from '../screens/CategoryDetailsScreen';
import CategoryFormScreen from '../screens/CategoryFormScreen';
import UsersScreen from '../screens/UsersScreen';
import UserDetailsScreen from '../screens/UserDetailsScreen';
import UserFormScreen from '../screens/UserFormScreen';

const routesWithComponent = [
    {
        id: 1,
        name: 'login',
        component: <LoginScreen/>,
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: null,
        backable: false,
        showHeader: false,
        presentation: 'push'
    },
    {
        id: 2,
        name: 'sale',
        component: <SaleScreen/>,
        title: 'အရောင်းစင်တာ',
        icon: faOpencart,
        color: 'yellow',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: null,
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 3,
        name: 'sold',
        component: <SoldScreen/>,
        title: 'ရောင်းပြီးပစ္စည်းများ',
        icon: faReceipt,
        color: 'white',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'view_sold_records',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 4,
        name: 'add_record',
        component: <AddRecordScreen/>,
        title: 'စာရင်းထည့်မှတ်တမ်း',
        icon: faFileSignature,
        color: 'sky',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: null,
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 5,
        name: 'debt',
        component: <DebtScreen/>,
        title: 'အကြွေးစာရင်း',
        icon: faFileInvoiceDollar,
        color: 'orange',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'access_debts',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 6,
        name: 'collateral',
        component: <CollateralScreen/>,
        title: 'အပေါင်စာရင်း',
        icon: faHandHoldingDollar,
        color: 'milk',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'view_collaterals',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 7,
        name: 'finance',
        component: <FinanceScreen/>,
        title: 'ငွေစာရင်းရှင်းတမ်း',
        icon: faMoneyBillTransfer,
        color: 'teal',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'access_finance',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 8,
        name: 'manage_request',
        component: <ManageRequestScreen/>,
        title: 'စာရင်းပြင်ရန်',
        icon: faPenToSquare,
        color: 'blue',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'manage_items',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 9,
        name: 'trader_weight',
        component: <TraderWeightScreen/>,
        title: 'ကုန်သည်အလျော့ထည့်ရန်',
        icon: faScaleUnbalanced,
        color: 'red',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'add_new_trader_weight',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 10,
        name: 'report',
        component: <ReportScreen/>,
        title: 'Chart & Report',
        icon: faChartLine,
        color: 'green',
        screenType: 'default',
        showInDrawer: true,
        requireAbility: 'view_report',
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 11,
        name: 'add_image',
        component: <AddImageScreen/>,
        title: 'ပုံထည့်ရန်',
        icon: faImage,
        color: 'yellow',
        screenType: 'stack',
        showInDrawer: true,
        requireAbility: 'add_new_items',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 12,
        name: 'groups',
        component: <GroupsScreen/>,
        title: 'ပစ္စည်းအမျိုးအစားများ',
        icon: faCubesStacked,
        color: 'orange',
        screenType: 'stack',
        showInDrawer: true,
        requireAbility: 'manage_groups',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 13,
        name: 'categories',
        component: <CategoriesScreen/>,
        title: 'အမျိုးအစားခွဲများ',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'manage_categories',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 14,
        name: 'users',
        component: <UsersScreen/>,
        title: 'ဝန်ထမ်းများ',
        icon: faUsers,
        color: 'pink',
        screenType: 'stack',
        showInDrawer: true,
        requireAbility: 'manage_users',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 15,
        name: 'networth',
        component: <NetWorthScreen/>,
        title: 'Net Worth',
        icon: faSackDollar,
        color: 'purple',
        screenType: 'stack',
        showInDrawer: true,
        requireAbility: 'view_networth',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 16,
        name: 'calculator',
        component: <CalculatorScreen/>,
        title: 'Calculator',
        icon: faCalculator,
        color: 'white',
        screenType: 'stack',
        showInDrawer: true,
        requireAbility: null,
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 17,
        name: 'group_form',
        component: <GroupFormScreen/>,
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'manage_groups',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 18,
        name: 'category_details',
        component: <CategoryDetailsScreen/>,
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'manage_categories',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 19,
        name: 'category_form',
        component: <CategoryFormScreen/>,
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'manage_categories',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 20,
        name: 'user_details',
        component: <UserDetailsScreen/>,
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'manage_users',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 21,
        name: 'user_form',
        component: <UserFormScreen/>,
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'manage_users',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 22,
        name: 'individual_sold',
        component: <IndivitualSoldScreen/>,
        title: 'မိမိရောင်းထားသောပစ္စည်းများ',
        icon: '',
        color: '',
        screenType: 'default',
        showInDrawer: false,
        requireAbility: null,
        backable: false,
        showHeader: false,
        presentation: 'default'
    },
    {
        id: 23,
        name: 'form',
        component: <FormScreen/>,
        title: 'ပစ္စည်းအသစ်ထည့်ရန်',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'add_new_items',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 24,
        name: 'added',
        component: <AddedScreen/>,
        title: 'ယခုထည့်လိုက်သောပစ္စည်းများ',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'add_new_items',
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 25,
        name: 'edit_requested',
        component: <EditRequestedScreen/>,
        title: 'Review Your Edit',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: null,
        backable: true,
        showHeader: true,
        presentation: 'default'
    },
    {
        id: 26,
        name: 'image_added',
        component: <ImageAddedScreen/>,
        title: 'ယခုထည့်ပြီးပုံများ',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: 'add_new_items',
        backable: false,
        showHeader: true,
        presentation: 'default'
    }
];

const routes = routesWithComponent.map( e => {
    const { component, ...withoutComponent } = e;
    return withoutComponent;
});

export { routesWithComponent };
export default routes;
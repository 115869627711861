import DateParser from "../../helper/dateParser";
import paperSizes from "../../helper/paperSizes";
import DateStats from "../DateStats";
import { memo, forwardRef } from "react";

const WithdrawVoucher = ({
    serial,
    item,
    amount,
    paidDateExceeded,
    fromDate,
    toDate,
    receivedBy,
    paperSize = 'a4'
}, ref) => {

    const dateParser = new DateParser();
    const { width, height } = paperSizes.find( e => e.name === paperSize ).mm;
    const costPerMonth = Math.round((item.amount * item.rate) / 100);

    return (
        <div 
            ref={ref} 
            className="flex flex-col bg-white" 
            style={{width: `${width}mm`, height: `${height}mm`}}
        >
            <div 
                className="flex flex-col bg-white" 
                style={{width: `${width}mm`, height: `${height}mm`}}
            >
                <div className="flex flex-col">
                    <div className="flex flex-1 flex-row">
                        <div className="w-1/3"></div>
                        <div className="w-1/3">
                            <div className="flex flex-col space-y-5 px-3 pt-3 pb-5 border-b border-dashed">

                                <div className="flex flex-col space-y-2">
                                    <div className="flex flex-row items-center space-x-2 justify-center">
                                        <span className="font-myawaddy text-4xl">aumif;pH</span>
                                        <span className="font-serif font-bold text-2xs pt-2">စိန် ~ ရွှေရတနာဆိုင်</span>
                                    </div>
                                    <div className="flex justify-center">
                                        <span className="text-2xs text-gray">
                                            အပေါင်ရွေးဘောင်ချာ
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="text-xs font-bold">
                                            ဘောင်ချာနံပါတ်
                                        </span>
                                        <span className="text-sm font-bold">
                                            { serial }
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="text-xs">
                                            အပေါင်ဘောင်ချာနံပါတ်
                                        </span>
                                        <span className="text-xs">
                                            { item.serial }
                                        </span>
                                    </div>
                                    <span className="text-xs">
                                        { item.customer_name } ({ item.customer_address })
                                    </span>
                                </div>

                                <div className="flex flex-row justify-between items-center">
                                    <span className="text-xs">
                                        တစ်လအတိုးနှုန်း
                                    </span>
                                    <span className="text-xs">
                                        { Number(item.rate) } %  ({ new Intl.NumberFormat().format(costPerMonth) } ကျပ်)
                                    </span>
                                </div>

                                <div className="flex flex-col space-y-3">
                                    <div className="flex flex-row justify-between items-center">
                                        <div className="flex flex-row items-center space-x-1">
                                            <span className="text-xs font-bold">
                                                { dateParser.toString(fromDate)}
                                            </span>
                                            <span className="text-2xs">
                                                မှ
                                            </span>
                                            <span className="text-xs font-bold">
                                                { dateParser.toString(toDate)}
                                            </span>
                                            <span className="text-2xs">
                                                ထိ
                                            </span>
                                        </div>
                                        <span className="text-xs">
                                            <DateStats
                                                date={ dateParser.compareDateDiff(fromDate, toDate) }
                                            />
                                        </span>
                                    </div>
                                    
                                    <div className="flex flex-row items-center space-x-5 py-3">
                                        <div className="flex flex-col space-y-2 flex-1 items-end text-sm">
                                            <div className="flex items-center h-5">
                                                <span>ယူငွေ</span>
                                            </div>
                                            <div className="flex flex-row space-x-1 items-center h-5">
                                                <span>
                                                    <DateStats
                                                        date={ dateParser.compareDateDiff(fromDate, toDate) }
                                                    />
                                                </span>
                                                <span>
                                                    စာ{ paidDateExceeded ? 'ငွေပြန်အမ်းရန်' : 'အတိုး'}
                                                </span>
                                            </div>

                                            {/* divider */}
                                            <div className="pt-1"></div>

                                            <div className="flex items-center h-5 font-bold">
                                                <span>စုစုပေါင်းသင့်ငွေ</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col space-y-2 justify-center items-end font-mono">
                                            <div className="flex items-center h-5">
                                                <span>{ new Intl.NumberFormat().format(item.amount) }</span>
                                            </div>
                                            <div className="flex items-center h-5">
                                                <span> { paidDateExceeded ? '-' : '+' } { new Intl.NumberFormat().format(Math.abs(amount)) }</span>
                                            </div>

                                            {/* divider */}
                                            <div className="border-b border-dashed border-b-gray w-full pt-1"></div>

                                            <div className="flex items-center font-bold h-5">
                                                <span>{ new Intl.NumberFormat().format(amount + item.amount) }</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <span className="text-2xs">
                                    တာဝန်ခံဝန်ထမ်း - { receivedBy.name }
                                </span>

                            </div>
                        </div>
                        <div className="w-1/3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(forwardRef(WithdrawVoucher));
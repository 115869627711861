import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useDispatch, useSelector } from 'react-redux';
import CollateralCard from '../components/CollateralCard';
import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NewCollateralModal, PayInterestModal, CollateralWithdrawModal } from '../components/modals';
import ItemListFrame from '../components/ItemListFrame';
import { setAlert } from '../store/reducers/app-reducer';
import MortgageVoucherPreviewModal from '../components/modals/MortgageVoucherPreviewModal';

const CollateralScreen = () => {

    const dispatch = useDispatch();
    const authUser = useSelector( state => state.app.authUser);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const groups = useSelector( state => state.app.groups);
    const fetchApi = useApiFetcher();
    const routeState = currentRoute.params?.state;
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        id: routeState?.id ?? null,
        group: routeState?.group ?? null,
        sort_by: {
            type: 'sold_date',
            order: 'desc'
        }
    });

    const [newCollateralId, setNewCollateralId] = useState(null);
    const [highlightedInterestId, setHighlightedInterestId] = useState(null);

    const [has_withdrawn, setHasWithDrawn] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: []
    });
    
    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

    //watcher
        useEffect( () => {
            setSearchFilters({
                ...defaultSearchFilters,
                sort_by: {
                    type: 'sold_date',
                    order: 'desc'
                }
            });
        }, [has_withdrawn]);

    //api_fetch
        useEffect( () => {
            if(search_filters.id){
                setHighlightedInterestId(search_filters.id);
            }

            setResponse({
                count: 0,
                items: []
            });
            fetchApi(
                'collaterals', 
                {has_withdrawn, page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setResponse
            );
            (page !== 2) && setPage(2);
        }, [search_filters, has_withdrawn]);

    //methods
        const loadMore = () => {
            if(!endResult){
                fetchApi(
                    'collaterals', 
                    {has_withdrawn, page, ...search_filters}, 
                    response.items, 
                    setShowLoading, 
                    setResponse, 
                    setPage
                );
            }
        }

        const updateList = useCallback( (id) => {
            setResponse( prev => {
                return {
                    count: prev - 1,
                    items: prev.items.filter( e => e.id !== id )
                }
            })
        }, []);

        const denyAction = useCallback( () => {
            dispatch(setAlert({
                msg: "You don't have permission to do this action!",
                status: 'error',
                timeout: 5000
            }));
        }, []);

        const handleScroll = (e) => {
            setShowNewCollateralButton(e.direction === 'up');
        }

    //for modal
        //pay interest modal
            const [targetPayInterestItem, setTargetPayInterestItem] = useState(null);
            const [showPayInterestModal, setshowPayInterestModal] = useState(false);

            const openPayInterestModal = useCallback( item => {
                setTargetPayInterestItem(item);
                setshowPayInterestModal(true);
            }, []);

            const closePayInterestModal = useCallback( () => {
                setshowPayInterestModal(false);
            }, []);

            const onInterestPaid = useCallback( (id) => {
                setHighlightedInterestId(id);
                closePayInterestModal();
                setSearchFilters({...search_filters}); 
            }, []);

        //withdraw modal
            const [targetWithdrawItem, settargetWithdrawItem] = useState(null);
            const [showCollateralWithdrawModal, setShowCollateralWithdrawModal] = useState(false);

            const openCollateralWithdrawModal = useCallback( item => {
                settargetWithdrawItem(item);
                setShowCollateralWithdrawModal(true);
            }, []);

            const closeCollateralWithdrawModal = useCallback( () => {
                setShowCollateralWithdrawModal(false);
            }, []);

            const onWithdrawn = useCallback( () => {
                closeCollateralWithdrawModal();
                setSearchFilters({...search_filters}); 
            }, []);

        //new collateral modal
            const [showNewCollateralButton, setShowNewCollateralButton] = useState(true);
            const [showNewCollateralModal, setShowNewCollateralModal] = useState(false);

            const closeNewCollateraModal = useCallback( () => {
                setShowNewCollateralModal(false);
            }, []);

            const onNewCollateralAdded = useCallback( (id) => {
                setNewCollateralId(id)
                closeNewCollateraModal();
                setSearchFilters({...search_filters}); 
            }, []);

        //voucher print modals
            //print collateral
                const [showCollateralVoucher, setShowCollateralVoucher] = useState(false);
                const [collateralVoucherData, setCollateralVoucherData] = useState(null);
        
                const openCollateralVoucher = (item) => {
                    setShowCollateralVoucher( () => {
                        setCollateralVoucherData({
                            serial: item.serial,
                            soldDate: item.sold_date,
                            expiryDate: item.expiry_date,
                            customer: {
                                name: item.customer_name,
                                address: item.customer_address,
                                phone: item.customer_phone,
                            },
                            amount: item.amount,
                            name: item.name,
                            weight: item.weight,
                            description: item.description,
                            rate: item.rate,
                            allowedMonths: item.allowed_months,
                            soldBy: item.sold_by,
                        });
                        return true;
                    });
                }
            
                const closeCollateralVoucher = () => {
                    setShowCollateralVoucher(false);
                    setCollateralVoucherData(null);
                }

            //print paid interest            
                const [showPaidInterestVoucher, setShowPaidInterestVoucher] = useState(false);
                const [paidInterestVoucherData, setPaidInterestVoucherData] = useState(null);
        
                const openPaidInterestVoucher = (collateral, paidInterest) => {
                    setShowPaidInterestVoucher( () => {
                        setPaidInterestVoucherData({
                            serial: paidInterest.serial,
                            item: collateral,
                            isWithdraw: paidInterest.is_withdraw,
                            amount: paidInterest.amount,
                            fromDate: paidInterest.from_date,
                            toDate: paidInterest.to_date,
                            receivedBy: paidInterest.received_by,
                        });
                        return true;
                    });
                }
            
                const closePaidInterestVoucher = () => {
                    setShowPaidInterestVoucher(false);
                    setPaidInterestVoucherData(null);
                }

    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="voucher_number_input"
                searchOnNavChange={false}
                enableAllSearch={true}
                enabledSearches={['date', 'id', 'word', 'customer']}
            />

            <ItemListFrame
                enabledSortTypes={['']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onScroll={handleScroll}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
                toggle={{
                    value: has_withdrawn,
                    color: 'sky',
                    left: {
                        value: false,
                        label: 'အပေါင်စာရင်းများ'
                    },
                    right: {
                        value: true,
                        label: 'ရွေးပြီးစာရင်းများ'
                    },
                    onChange: setHasWithDrawn
                }}
            >
                <div className="flex-1 flex flex-row flex-wrap mt-2">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <CollateralCard
                                key={item.id}
                                onTakeAction={updateList}
                                onPrintPaidInterest={openPaidInterestVoucher}
                                data={item}
                                type="collateral"
                                details={['sold']}
                                highlightedCollateralId={newCollateralId}
                                highlightedInterestId={highlightedInterestId}
                                buttons={{
                                    show: !has_withdrawn,
                                    normal_buttons: [
                                                        {
                                                            id: 1,
                                                            name: 'အတိုးပေး',
                                                            color: 'sky',
                                                            onClick: () => openPayInterestModal(item)
                                                        },
                                                        {
                                                            id: 2,
                                                            name: 'ရွေး',
                                                            color: 'green',
                                                            onClick: () => openCollateralWithdrawModal(item)
                                                        }
                                                    ],

                                    setting_mode_buttons:[
                                                            {
                                                                id: 1,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'Print',
                                                                icon: faPrint,
                                                                textColor: 'black',
                                                                color: 'muted',
                                                                onClick: () => {
                                                                    if(window.confirm(`
                                                                        \nအပေါင်လက်ခံဘောင်ချာ ထပ်ထုတ်မှာသေချာပါသလား?
                                                                        \nအပေါင်ဘောင်ချာသည် တစ်ရွက်သာရှိသင့်ပါသည်။
                                                                        \nဝယ်သူအား အပေါင်ဘောင်ချာတစ်ခုထက် ပိုမပေးမိစေရန် သတိပြုပါ!
                                                                    `)){
                                                                        if(authUser.abilities.includes('add_new_collaterals')){
                                                                            openCollateralVoucher(item);
                                                                        } else {
                                                                            denyAction();
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        ]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>

            <div 
                className="fixed mb-5 right-6 sm:right-24 transition-position duration-500"
                style={{
                    bottom: showNewCollateralButton ? 40 : -100
                }}
            >
                <button
                    className="rounded-full backdrop-blur-sm bg-blue/70 w-14 h-14"
                    onClick={() => {
                        if(authUser.abilities.includes('add_new_collaterals')){
                            setShowNewCollateralModal(true);
                        } else {
                            denyAction();
                        }  
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} color="white"/>
                </button>
            </div>

            <NewCollateralModal
                show={showNewCollateralModal}
                onAdded={onNewCollateralAdded}
                onClose={closeNewCollateraModal}
            />

            <PayInterestModal
                show={showPayInterestModal}
                item={targetPayInterestItem}
                onPaid={onInterestPaid}
                onClose={closePayInterestModal}
            />

            <CollateralWithdrawModal
                show={showCollateralWithdrawModal}
                item={targetWithdrawItem}
                onWithdrawn={onWithdrawn}
                onClose={closeCollateralWithdrawModal}
                isOneMonthMinimum={true}
            />

            <MortgageVoucherPreviewModal
                tasks={['print']}
                type="new_collateral"
                show={showCollateralVoucher}
                data={collateralVoucherData}
                onDone={closeCollateralVoucher}
                onClose={closeCollateralVoucher}
            />

            <MortgageVoucherPreviewModal
                tasks={['print']}
                type="pay_interest"
                show={showPaidInterestVoucher}
                data={paidInterestVoucherData}
                onDone={closePaidInterestVoucher}
                onClose={closePaidInterestVoucher}
            />
        </>
    )
}

export default CollateralScreen;